@import "variables";
@import 'flash';

.contact-form {
  display: flex;
  flex-direction: column;
  max-width: 650px;
  width: 650px;
  padding: 15px;
  box-sizing: border-box;
  margin: 0 auto;
  margin-top: 20px;
  @media @mobile {
    max-width: 100%;
    width: 100%;
  }
  label {
    font-size: 16px;
    color: rgba(black, 0.5);
    text-align: left;
    margin-bottom: 15px;
  }
  input, textarea {
    display: block;
    padding: 16px 17px;
    font-size: 16px;
    background-color: rgba(255,255,255,0.05);
    border-radius: 10px;
    border: 1px solid #cbcbcb;
    outline: none;
    box-shadow: 0 0 4px fade(#000, 10%);
    width: 100%;
    box-sizing: border-box;
    resize: vertical;
    &:focus, &:active {
      border: 1px solid darken(#cbcbcb, 20%);
    }
  }
}

.contact-form__vertical {
  display: flex;
  @media @mobile {
    flex-direction: column;
  }
}

.form-group {
  display: flex;
  flex-direction: column;
  margin: 0 10px 25px 10px;
  flex-grow: 1;
}

.mailto {
  text-decoration: none;
  color: @primary-color;
  &:hover {
    color: darken(@primary-color, 10%);
  }
}
