.navigation {
  width: 100dvw;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.navigation--desktop {
  align-items: center;
  height: 90px;
}

@media only screen and (width <= 700px) {
  .navigation--desktop .navigation__item {
    display: none;
  }
}

.navigation__left {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.navigation__logo {
  background-image: url("logo.c55c84db.svg");
  background-position: 0;
  background-repeat: no-repeat;
  background-size: 100%;
  width: 150px;
  height: 25.3731px;
  margin-left: 30px;
  display: inline-block;
  position: relative;
}

.navigation__item {
  color: #575757;
  margin: 0 8px;
  padding: 10px;
  font-size: 14px;
  text-decoration: none;
}

.navigation__item:hover {
  color: #8a8a8a;
}

.navigation__item--login {
  margin: 0 5px;
  padding: 11px 20px;
}

@media only screen and (width <= 700px) {
  .navigation__item--login {
    display: none;
  }
}

.navigation__item--signup {
  margin: 0 30px 0 5px;
  padding: 13px 22px;
}

.navigation__center {
  margin-left: auto;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.navigation__right {
  margin-left: auto;
}

.navigation__burger {
  box-sizing: content-box;
  cursor: pointer;
  flex-direction: column;
  justify-content: space-between;
  width: 26px;
  height: 18px;
  padding: 35px;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
}

@media only screen and (width >= 701px) {
  .navigation__burger {
    display: none;
  }
}

.navigation__burger__stripe {
  background: #000;
  width: 100%;
  height: 2px;
}

.navigation--mobile {
  background-color: #fff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100dvw;
  height: 100dvh;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2 !important;
}

@media only screen and (width >= 701px) {
  .navigation--mobile {
    display: none;
  }
}

.navigation--mobile .navigation__item {
  max-width: 160px;
  margin: 10px auto;
  display: block;
}

.btn {
  color: #fff;
  cursor: pointer;
  background: #ff6553;
  border: none;
  border-radius: 5px;
  outline: none;
  padding: 6px 12px;
  font-size: 16px;
}

.btn--large {
  border-radius: 10px;
  padding: 12px 18px;
}

.btn:hover {
  color: #fff;
  background: #ff4e39;
}

.btn:active {
  color: #fff;
  background: #ff3720;
}

.btn-undercopy {
  color: #898989;
  margin-top: 8px;
  font-size: 13px;
  font-weight: 300;
}

.card {
  border: 1px solid #cbcbcb;
  border-radius: 10px;
  padding: 25px;
  box-shadow: 0 0 4px #0000001a;
}

.card--peach {
  border: 3px solid #ff6553;
  box-shadow: 0 0 4px #ff655380;
}

@media only screen and (width <= 700px) {
  .card--peach .row {
    flex-direction: column-reverse;
  }
}

.card__img {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: 2em;
}

@media only screen and (width <= 700px) {
  .card__img {
    margin-left: 0;
  }
}

.card__img--rocket {
  background-image: url("rocket.1f56764c.svg");
  width: 300px;
  height: 244.717px;
}

.card__img--achievement {
  background-image: url("achievement.a0920e23.svg");
  width: 300px;
  height: 300px;
}

.card--concert {
  padding: 0;
  overflow: hidden;
}

.card--concert img {
  object-fit: cover;
  width: 100%;
  display: block;
}

.card--binary {
  background-image: url("binary-bg.755815a1.png");
  background-position: bottom;
  background-repeat: repeat-x;
}

@media only screen and (min-device-pixel-ratio: 2) {
  .card--binary {
    background-image: url("binary-bg@2x.e36cc5de.png");
  }
}

.window {
  border-radius: 10px;
  flex-direction: column;
  margin-top: 200px;
  display: flex;
  position: relative;
  box-shadow: 0 2px 28px #0003;
}

.window__header {
  vertical-align: baseline;
  border-bottom: 1px solid #ededed;
  flex-direction: row;
  align-items: center;
  height: 45px;
  padding: 0 5px;
  display: flex;
}

.window__header__button {
  background: #dfdfdf;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin-left: 8px;
}

.window__img {
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.window__illu {
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
}

.window__illu--mountain {
  background-image: url("mountain.5ae068aa.svg");
  width: 350px;
  height: 285.504px;
  top: -285.504px;
  left: 0;
}

@media only screen and (width <= 700px) {
  .window__illu--mountain {
    width: 200px;
    height: 163.145px;
    top: -165px;
  }
}

.window__illu--controller {
  background-image: url("controller.3b435a74.svg");
  width: 200px;
  height: 253.535px;
  bottom: 120px;
  right: -150px;
}

@media only screen and (width <= 700px) {
  .window__illu--controller {
    display: none;
  }
}

.quote {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.quote__copy {
  color: #8c8c8c;
  text-align: center;
  font-size: 26px;
  font-weight: bold;
}

.quote__author {
  flex-direction: row;
  align-items: center;
  margin-top: 6px;
  display: flex;
}

.quote__author__img {
  background-image: url("bob-pillow.2e03b442.svg");
  background-position: center;
  background-repeat: no-repeat;
  flex-grow: 0;
  flex-shrink: 0;
  width: 50px;
  height: 53.3898px;
  margin-right: 12px;
}

.quote__author__name {
  font-size: 18px;
  font-weight: 600;
}

.quote__author__title {
  font-size: 15px;
  font-weight: 300;
}

.footer {
  border-top: 1px solid #cacaca;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 1em;
  display: flex;
}

.footer__eu {
  font-size: 20px;
  font-weight: bold;
}

.footer__eu-flag {
  font-size: 30px;
}

.footer__item {
  margin: 0 20px;
}

.tooltipped {
  position: relative;
}

.tooltipped:after {
  z-index: 1000000;
  -webkit-font-smoothing: subpixel-antialiased;
  color: #191919;
  text-align: center;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: break-word;
  white-space: pre;
  pointer-events: none;
  content: attr(aria-label);
  opacity: 0;
  background: #fffffffa;
  border-radius: 3px;
  padding: 5px 6px;
  font-size: 11px;
  text-decoration: none;
  display: none;
  position: absolute;
  box-shadow: 0 3px 6px #344a6120;
}

.tooltipped:before {
  z-index: 1000001;
  color: #fffffffa;
  pointer-events: none;
  content: "";
  opacity: 0;
  border: 6px solid #0000;
  width: 0;
  height: 0;
  display: none;
  position: absolute;
}

@keyframes tooltip-appear {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.tooltipped:hover:before, .tooltipped:active:before, .tooltipped:focus:before, .tooltipped:hover:after, .tooltipped:active:after, .tooltipped:focus:after {
  animation-name: tooltip-appear;
  animation-duration: .15s;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
  animation-delay: 0;
  text-decoration: none;
  display: inline-block;
}

.tooltipped-no-delay:hover:before, .tooltipped-no-delay:active:before, .tooltipped-no-delay:focus:before, .tooltipped-no-delay:hover:after, .tooltipped-no-delay:active:after, .tooltipped-no-delay:focus:after {
  animation-delay: 0s;
}

.tooltipped-multiline:hover:after, .tooltipped-multiline:active:after, .tooltipped-multiline:focus:after {
  display: table-cell;
}

.tooltipped-s:after, .tooltipped-se:after, .tooltipped-sw:after {
  margin-top: 6px;
  top: 100%;
  right: 50%;
}

.tooltipped-s:before, .tooltipped-se:before, .tooltipped-sw:before {
  border-bottom-color: #fffffffa;
  margin-right: -6px;
  top: auto;
  bottom: -7px;
  right: 50%;
}

.tooltipped-se:after {
  margin-left: -3px;
  left: 50%;
  right: auto;
}

.tooltipped-sw:after {
  margin-right: -3px;
}

.tooltipped-n:after, .tooltipped-ne:after, .tooltipped-nw:after {
  margin-bottom: 10px;
  bottom: 100%;
  right: 50%;
}

.tooltipped-n:before, .tooltipped-ne:before, .tooltipped-nw:before {
  border-top-color: #fffffffa;
  margin-right: -6px;
  top: -11px;
  bottom: auto;
  right: 50%;
}

.tooltipped-ne:after {
  margin-left: -3px;
  left: 50%;
  right: auto;
}

.tooltipped-nw:after {
  margin-right: -3px;
}

.tooltipped-s:after, .tooltipped-n:after {
  transform: translateX(50%);
}

.tooltipped-w:after {
  margin-right: 6px;
  bottom: 50%;
  right: 100%;
  transform: translateY(50%);
}

.tooltipped-w:before {
  border-left-color: #fffffffa;
  margin-top: -6px;
  top: 50%;
  bottom: 50%;
  left: -7px;
}

.tooltipped-e:after {
  margin-left: 6px;
  bottom: 50%;
  left: 100%;
  transform: translateY(50%);
}

.tooltipped-e:before {
  border-right-color: #fffffffa;
  margin-top: -6px;
  top: 50%;
  bottom: 50%;
  right: -7px;
}

.tooltipped-align-right-1:after, .tooltipped-align-right-2:after {
  margin-right: 0;
  right: 0;
}

.tooltipped-align-right-1:before {
  right: 10px;
}

.tooltipped-align-right-2:before {
  right: 15px;
}

.tooltipped-align-left-1:after, .tooltipped-align-left-2:after {
  margin-left: 0;
  left: 0;
}

.tooltipped-align-left-1:before {
  left: 5px;
}

.tooltipped-align-left-2:before {
  left: 10px;
}

.tooltipped-multiline:after {
  word-wrap: break-word;
  white-space: pre-line;
  border-collapse: separate;
  width: max-content;
  max-width: 200px;
}

.tooltipped-multiline.tooltipped-s:after, .tooltipped-multiline.tooltipped-n:after {
  left: 50%;
  right: auto;
  transform: translateX(-50%);
}

.tooltipped-multiline.tooltipped-w:after, .tooltipped-multiline.tooltipped-e:after {
  right: 100%;
}

.tooltipped-sticky:before, .tooltipped-sticky:after {
  display: inline-block;
}

.tooltipped-sticky.tooltipped-multiline:after {
  display: table-cell;
}

.pricing__table__holder {
  margin-left: -20px;
  margin-right: -20px;
  padding: 0 20px;
  overflow: auto;
}

.pricing__table {
  border-collapse: collapse;
  table-layout: fixed;
  background: #fff;
  border-radius: 10px;
  width: 100%;
  min-width: 700px;
  max-width: 960px;
  margin: 100px auto 0;
  font-size: 16px;
  box-shadow: 0 2px 28px #0003;
}

@media (width <= 700px) {
  .pricing__table {
    table-layout: initial;
  }
}

.pricing__table th, .pricing__table td {
  text-align: center;
  vertical-align: middle;
  border: none;
  align-items: center;
  height: 65px;
  padding: 0 10px;
}

.pricing__table th {
  background-color: #fff;
  font-size: 15px;
  line-height: 17px;
}

.pricing__table__container {
  margin-left: -20px;
  margin-right: -20px;
  padding: 0 20px;
}

.pricing__table__row:nth-child(2n) {
  background-color: #fff7f6;
}

.pricing__table__light {
  color: #6363bf33;
}

.pricing__table__title {
  text-transform: uppercase;
  font-size: 16px;
  text-align: left !important;
  padding-left: 30px !important;
}

.pricing__table__price {
  color: #ff6553;
  justify-content: center;
  align-items: center;
  min-width: 130px;
  font-size: 24px;
  display: flex;
}

.pricing__table__price__dollar {
  margin-bottom: 10px;
  margin-right: 3px;
  font-size: 14px;
}

.pricing__table__price__month {
  margin-top: 10px;
  margin-left: 1px;
  font-size: 14px;
}

.pricing__table__tip {
  color: #6363bf;
  cursor: default;
  background: #6363bf1a;
  border-radius: 6px;
  width: 12px;
  height: 12px;
  margin-left: 3px;
  font-size: 8px;
  line-height: 12px;
  display: inline-block;
  position: relative;
  transform: translateY(-5px);
}

.enterprise__plan {
  box-sizing: border-box;
  background: #fff7f6;
  border-radius: 10px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 960px;
  margin: 25px auto 0;
  padding: 50px;
  display: flex;
  box-shadow: 0 2px 28px #0003;
}

.enterprise__plan .pricing__plan__title {
  color: #ff6553;
}

.enterprise__plan .pricing__plan__feature {
  color: #ff6553;
  text-align: center;
  font-size: 13px;
}

.enterprise__plan .pricing__plan__subfeature {
  font-weight: 300;
}

@media only screen and (width <= 800px) {
  .enterprise__plan {
    flex-direction: column;
  }

  .enterprise__plan .jumbotron__signup {
    margin-top: 30px;
  }
}

@media only screen and (width >= 801px) {
  .enterprise__plan .pricing__plan__title, .enterprise__plan .pricing__plan__subfeature, .enterprise__plan .jumbotron__signup {
    margin: 0;
  }
}

.faq__data {
  text-align: left;
  flex-direction: row;
  justify-content: center;
  display: flex;
}

.faq__side {
  max-width: 430px;
}

.faq__side--left {
  margin-right: 50px;
}

.faq__side--right {
  margin-left: 50px;
}

.faq__question {
  width: 100%;
  margin-top: 30px;
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 500;
}

.faq__answer {
  opacity: .5;
  width: 100%;
  font-size: 16px;
  font-weight: 300;
  line-height: 30px;
}

@media only screen and (width <= 900px) {
  .faq__data {
    flex-direction: column;
  }

  .faq__side {
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}

.flash {
  flex-direction: column;
  margin-bottom: 20px;
  display: flex;
}

.flash__item {
  text-align: left;
  color: #000;
  background: #ffffff3d;
  border-radius: 4px;
  margin-bottom: 10px;
  padding: 20px 25px;
  box-shadow: 0 36px 54px #0000001a;
}

.flash__item--error {
  border-left: 5px solid red;
}

.flash__item--success {
  border-left: 5px solid #23e223;
}

.contact-form {
  box-sizing: border-box;
  flex-direction: column;
  width: 650px;
  max-width: 650px;
  margin: 20px auto 0;
  padding: 15px;
  display: flex;
}

@media only screen and (width <= 700px) {
  .contact-form {
    width: 100%;
    max-width: 100%;
  }
}

.contact-form label {
  color: #00000080;
  text-align: left;
  margin-bottom: 15px;
  font-size: 16px;
}

.contact-form input, .contact-form textarea {
  box-sizing: border-box;
  resize: vertical;
  background-color: #ffffff0d;
  border: 1px solid #cbcbcb;
  border-radius: 10px;
  outline: none;
  width: 100%;
  padding: 16px 17px;
  font-size: 16px;
  display: block;
  box-shadow: 0 0 4px #0000001a;
}

.contact-form input:focus, .contact-form textarea:focus, .contact-form input:active, .contact-form textarea:active {
  border: 1px solid #989898;
}

.contact-form__vertical {
  display: flex;
}

@media only screen and (width <= 700px) {
  .contact-form__vertical {
    flex-direction: column;
  }
}

.form-group {
  flex-direction: column;
  flex-grow: 1;
  margin: 0 10px 25px;
  display: flex;
}

.mailto {
  color: #ff6553;
  text-decoration: none;
}

.mailto:hover {
  color: #ff3720;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body {
  color: #000;
  max-width: 100dvw;
  font-family: Inter, sans-serif;
  overflow-x: hidden;
}

input, textarea {
  color: #000;
  font-family: Inter, sans-serif;
}

body {
  overflow: initial;
  flex-direction: column;
  max-width: 945px;
  margin: 0 auto;
  display: flex;
}

@media only screen and (width <= 700px) {
  body {
    margin: 0 10px;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

h1 {
  margin: 3em 0 20px;
  font-size: 40px;
  font-weight: 600;
  line-height: 1.4;
}

h2 {
  margin: 10px 0;
  font-size: 30px;
  font-weight: 600;
  line-height: 1.4;
}

h3 {
  font-size: 22px;
  font-weight: 600;
  line-height: 1.4;
}

p {
  color: #575757;
  font-size: 23px;
  font-weight: 300;
  line-height: 31px;
}

.row {
  flex-direction: row;
  display: flex;
}

.row--center {
  align-items: center;
}

@media only screen and (width <= 700px) {
  .row--two {
    flex-direction: column;
  }
}

.row--two .card, .row--two .card:first-child {
  width: 100%;
}

.row--two .card:first-child:first-child {
  margin-right: 30px;
}

@media only screen and (width <= 700px) {
  .row--two .card:first-child:first-child {
    margin-bottom: 30px;
    margin-right: 0;
  }
}

.col {
  flex-direction: column;
  display: flex;
}

.col--center {
  text-align: center;
  align-items: center;
}

.mb1 {
  margin-bottom: 1em;
}

.mb2 {
  margin-bottom: 2em;
}

.mb3 {
  margin-bottom: 3em;
}

.mb4 {
  margin-bottom: 4em;
}

.mb5 {
  margin-bottom: 5em;
}

.mb6 {
  margin-bottom: 6em;
}

.mb7 {
  margin-bottom: 7em;
}

.mb8 {
  margin-bottom: 8em;
}

.mb9 {
  margin-bottom: 9em;
}

.mb10 {
  margin-bottom: 10em;
}

.mt1 {
  margin-top: 1em;
}

.mt2 {
  margin-top: 2em;
}

.mt3 {
  margin-top: 3em;
}

.mt4 {
  margin-top: 4em;
}

.mt5 {
  margin-top: 5em;
}

.mt6 {
  margin-top: 6em;
}

.mt7 {
  margin-top: 7em;
}

.mt8 {
  margin-top: 8em;
}

.mt9 {
  margin-top: 9em;
}

.mt10 {
  margin-top: 10em;
}

.text-gradient {
  color: #ff6553;
}

@supports ((-webkit-background-clip: text) or (background-clip: text)) {
  .text-gradient {
    color: #0000;
    background-image: linear-gradient(90deg, #ff5180, #ff7f19);
    -webkit-background-clip: text;
    background-clip: text;
  }
}

.scaledrone-link {
  color: #6363bf;
  font-weight: bold;
}

.design-illustration {
  background-position: center;
  background-repeat: no-repeat;
  flex-grow: 0;
  flex-shrink: 0;
}

.design-illustration__main {
  background-image: url("sitting-in-laptop.85b4db07.svg");
  width: 350px;
  height: 262.915px;
}

.design-illustration__left {
  background-image: url("left-noise.4e82983e.svg");
  width: 150px;
  height: 459.649px;
  position: absolute;
  top: 10px;
  left: -100px;
}

@media only screen and (width <= 700px) {
  .design-illustration__left {
    display: none;
  }
}

.design-illustration__right {
  background-image: url("right-noise.eb3fbb14.svg");
  width: 200px;
  height: 494.167px;
  position: absolute;
  top: -20px;
  right: -100px;
}

@media only screen and (width <= 700px) {
  .design-illustration__right {
    display: none;
  }
}

/*# sourceMappingURL=contact.4238f7fa.css.map */
