.btn {
  background: @primary-color;
  color: white;
  font-size: 16px;
  padding: 6px 12px;
  border-radius: 5px;
  outline: none;
  border: none;
  cursor: pointer;
  &--large {
    padding: 12px 18px;
    border-radius: 10px;
  }
  &:hover {
    background: darken(@primary-color, 5%);
    color: white;
  }
  &:active {
    background: darken(@primary-color, 10%);
    color: white;
  }
}

.btn-undercopy {
  font-size: 13px;
  color: #898989;
  margin-top: 8px;
  font-weight: 300;
}