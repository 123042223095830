.flash {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.flash__item {
  padding: 20px 25px;
  border-radius: 4px;
  margin-bottom: 10px;
  text-align: left;

  color: black;
  background: rgba(255, 255, 255, 0.24);
  box-shadow: 0 36px 54px 0 rgba(0,0,0,.1);
}

.flash__item--error {
  border-left: 5px solid red;
}

.flash__item--success {
  border-left: 5px solid rgb(35, 226, 35);
}