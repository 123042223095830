.window {
  border-radius: 10px;
  box-shadow: 0 2px 28px 0 rgba(0,0,0,0.2);
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 200px;
}

.window__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 45px;
  vertical-align: baseline;
  padding: 0 5px;
  border-bottom: 1px solid #ededed;
}

.window__header__button {
  height: 10px;
  width: 10px;
  background: #DFDFDF;
  border-radius: 50%;
  margin-left: 8px;
}

.window__img {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.window__illu {
  position: absolute;
  background-position: center;
  background-repeat: no-repeat;
  &--mountain {
    background-image: url('/public/mountain.svg');
    // original size: 407x332
    @width: 350px;
    @height: calc(@width * 332/407);
    width: @width;
    height: @height;
    top: calc(@height * -1);
    left: 0;
    @media @mobile {
      width: 200px;
      height: calc(200px * 332/407);
      top: -165px;
    }
  }
  &--controller {
    background-image: url('/public/controller.svg');
    // original size: 251x198
    @width: 200px;
    @height: calc(@width * 251/198);
    width: @width;
    height: @height;
    bottom: 120px;
    right: calc(-1 * (@width - 50px));
    @media @mobile {
      display: none;
    }
  }
}