@import "variables";
@import "navigation";
@import "buttons";
@import "cards";
@import "window";
@import "quote";
@import "footer";
@import "tooltips";
@import "pricing";
@import "contact";

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100dvw;
  overflow-x: hidden;
  font-family: 'Inter', sans-serif;
  color: @text-color;
}

input, textarea {
  font-family: 'Inter', sans-serif;
  color: @text-color;
}

body {
  display: flex;
  flex-direction: column;
  max-width: 945px;
  overflow: initial;
  margin: 0 auto;
  @media @mobile {
    margin: 0 10px;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

h1 {
  font-size: 40px;
  font-weight: 600;
  margin: 3em 0 20px 0;
  line-height: 1.4;
}

h2 {
  font-size: 30px;
  font-weight: 600;
  margin: 10px 0;
  line-height: 1.4;
}

h3 {
  font-size: 22px;
  font-weight: 600;
  line-height: 1.4;
}

p {
  font-size: 23px;
  line-height: 31px;
  color: @text-color-light;
  font-weight: 300;
}

.row {
  display: flex;
  flex-direction: row;
  &--center {
    align-items: center;
  }
  &--two {
    @media @mobile {
      flex-direction: column;
    }
    .card {
      width: 100%;
    }
    .card:first-child {
      width: 100%;
      &:first-child {
        margin-right: 30px;
        @media @mobile {
          margin-right: 0;
          margin-bottom: 30px;
        }
      }
    }
  }
}

.col {
  display: flex;
  flex-direction: column;
  &--center {
    align-items: center;
    text-align: center;
  }
}

.mb1 { margin-bottom: 1em; }
.mb2 { margin-bottom: 2em; }
.mb3 { margin-bottom: 3em; }
.mb4 { margin-bottom: 4em; }
.mb5 { margin-bottom: 5em; }
.mb6 { margin-bottom: 6em; }
.mb7 { margin-bottom: 7em; }
.mb8 { margin-bottom: 8em; }
.mb9 { margin-bottom: 9em; }
.mb10 { margin-bottom: 10em; }

.mt1 { margin-top: 1em; }
.mt2 { margin-top: 2em; }
.mt3 { margin-top: 3em; }
.mt4 { margin-top: 4em; }
.mt5 { margin-top: 5em; }
.mt6 { margin-top: 6em; }
.mt7 { margin-top: 7em; }
.mt8 { margin-top: 8em; }
.mt9 { margin-top: 9em; }
.mt10 { margin-top: 10em; }

.text-gradient {
  color: @primary-color;
  @supports (background-clip: text) {
    background-image: linear-gradient(90deg, #FF5180, #FF7F19);
    background-clip: text;
    color: transparent;
  }
}

.scaledrone-link {
  color: #6363bf;
  font-weight: bold;
}

.design-illustration {
  background-position: center;
  background-repeat: no-repeat;
  flex-shrink: 0;
  flex-grow: 0;
  &__main {
    background-image: url('/public/sitting-in-laptop.svg');
    // original size: 422x317
    @width: 350px;
    @height: calc(@width * 317/422);
    width: @width;
    height: @height;
  }
  &__left {
    background-image: url('/public/left-noise.svg');
    // original size: 171x524
    @width: 150px;
    @height: calc(@width * 524/171);
    width: @width;
    height: @height;

    position: absolute;
    top: 10px;
    left: -100px;
    @media @mobile {
      display: none;
    }
  }
  &__right {
    background-image: url('/public/right-noise.svg');
    // original size: 240x593
    @width: 200px;
    @height: calc(@width * 593/240);
    width: @width;
    height: @height;

    position: absolute;
    top: -20px;
    right: -100px;
    @media @mobile {
      display: none;
    }
  }
}