.quote {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.quote__copy {
  color: #8C8C8C;
  font-size: 26px;
  text-align: center;
  font-weight: bold;
}

.quote__author {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 6px;
  &__img {
    background-image: url('/public/bob-pillow.svg');
    background-position: center;
    background-repeat: no-repeat;
    flex-shrink: 0;
    flex-grow: 0;
    // original size: 59x63
    @width: 50px;
    @height: calc(@width * 63/59);
    width: @width;
    height: @height;
    margin-right: 12px;
  }
  &__name {
    font-size: 18px;
    font-weight: 600;
  }
  &__title {
    font-size: 15px;
    font-weight: 300;
  }
}