@import "variables";

@desktop: ~"only screen and (min-width: 701px)";
@mobile: ~"only screen and (max-width: 700px)";

.pricing__table__holder {
  overflow: auto;
  margin-left: -20px;
  margin-right: -20px;
  padding: 0 20px;
}

.pricing__table {
  @min-width: 700px;
  box-shadow: 0 2px 28px 0 rgba(0,0,0,0.2);
  border-radius: 10px;
  background: white;
  max-width: calc(192px * 5);
  width: 100%;
  margin: 100px auto 0 auto;
  border-collapse: collapse;
  table-layout: fixed;
  font-size: 16px;
  min-width: @min-width;
  @media (max-width: @min-width) {
    table-layout: initial;
  }
  th, td {
    padding: 0 10px;
    border: none;
    height: 65px;
    text-align: center; 
    vertical-align: middle;
    align-items: center;
  }
  th {
    background-color: white;
    font-size: 15px;
    line-height: 17px;
  }
  &__container {
    // overflow: auto;
    margin-left: -20px;
    margin-right: -20px;
    padding: 0 20px;
  }
  &__row {
    &:nth-child(even) {
      background-color: lighten(@primary-color, 32%);
    }
  }
  &__light {
    color: rgba(#6363BF, 0.2);
  }
  &__title {
    font-size: 16px;
    text-transform: uppercase;
    padding-left: 30px !important;
    text-align: left !important;
  }
  &__price {
    font-size: 24px;
    color: @primary-color;
    min-width: 130px;
    display: flex;
    align-items: center;
    justify-content: center;
    &__dollar {
      font-size: 14px;
      margin-bottom: 10px;
      margin-right: 3px;
    }
    &__month {
      font-size: 14px;
      margin-top: 10px;
      margin-left: 1px;
    }
  }
  &__tip {
    height: 12px;
    width: 12px;
    border-radius: 6px;
    background: rgba(#6363BF, 0.1);
    display: inline-block;
    line-height: 12px;
    position: relative;
    margin-left: 3px;
    color: #6363BF;
    font-size: 8px;
    transform: translateY(-5px);
    cursor: default;
  }
}

.enterprise__plan {
  max-width: 192px * 5;
  background: lighten(@primary-color, 32%);
  box-shadow: 0 2px 28px 0 rgba(0,0,0,0.2);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 25px auto 0 auto;
  padding: 50px;
  box-sizing: border-box;
  width: 100%;
  .pricing__plan__title {
    color: @primary-color;
  }
  .pricing__plan__feature {
    color: @primary-color;
    font-size: 13px;
    text-align: center;
  }
  .pricing__plan__subfeature {
    font-weight: 300;
  }
  @media only screen and (max-width: 800px) {
    flex-direction: column;
    .jumbotron__signup {
      margin-top: 30px;
    }
  }
  @media only screen and (min-width: 801px) {
    .pricing__plan__title,
    .pricing__plan__subfeature,
    .jumbotron__signup {
      margin: 0;
    }
  }
}

.faq {
  &__data {
    display: flex;
    flex-direction: row;
    text-align: left;
    justify-content: center;
  }
  &__side {
    max-width: 430px;
    &--left {
      margin-right: 50px;
    }
    &--right {
      margin-left: 50px;
    }
  }
  &__question {
    margin-bottom: 10px;
    margin-top: 30px;
    font-size: 20px;
    font-weight: 300;
    width: 100%;
    font-weight: 500;
  }
  &__answer {
    opacity: 0.5;
    font-size: 16px;
    line-height: 30px;
    font-weight: 300;
    width: 100%;
  }
  @media only screen and (max-width: 900px) {
    &__data {
      flex-direction: column;
    }
    &__side {
      max-width: 100%;
      margin-left: 0;
      margin-right: 0;
    }
  }
}
