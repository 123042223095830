.card {
  border-radius: 10px;
  border: 1px solid #cbcbcb;
  box-shadow: 0 0 4px fade(#000, 10%);
  padding: 25px;

  &--peach {
    border: 3px solid @primary-color;
    box-shadow: 0 0 4px fade(@primary-color, 50%);
    @media @mobile {
      .row {
        flex-direction: column-reverse;      
      }
    }
  }

  &__img {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    flex-shrink: 0;
    flex-grow: 0;
    margin-left: 2em;
    @media @mobile {
      margin-left: 0;
    }
    &--rocket {
      background-image: url('/public/rocket.svg');
      // original size: 308x284
      @width: 300px;
      @height: calc(@width * 332/407);
      width: @width;
      height: @height;
    }
    &--achievement {
      background-image: url('/public/achievement.svg');
      // original size: 1000x1000
      width: 300px;
      height: 300px;
    }
  }

  &--concert {
    padding: 0;
    overflow: hidden;
    img {
      object-fit: cover;
      width: 100%;
      display: block;
    }
  }
  &--binary {
    background-image: url('/public/binary-bg.png');
    background-position: bottom;
    background-repeat: repeat-x;
    @media
    only screen and (min-device-pixel-ratio: 2) {
      background-image: url('/public/binary-bg@2x.png');
    }
  }
}