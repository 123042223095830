@desktop-menu: ~"only screen and (min-width: 701px)";
@mobile-menu: ~"only screen and (max-width: 700px)";

.navigation {
  display: flex;
  position: absolute;
  width: 100dvw;
  left: 0;
  top: 0;
}

.navigation--desktop {
  height: 90px;
  align-items: center;
  .navigation__item {
    @media @mobile-menu {
      display: none;
    }
  }
}

.navigation__left {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.navigation__logo {
  background-image: url('/public/logo.svg');
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: left center;
  width: 150px;
  height: calc(34/201 * 150px);
  display: inline-block;
  position: relative;
  margin-left: 30px;
}

.navigation__item {
  font-size: 14px;
  color: @text-color-light;
  text-decoration: none;
  padding: 10px;
  margin: 0 8px;
  &:hover {
    color: lighten(@text-color-light, 20%);
  }
}

.navigation__item--login {
  margin: 0 5px;
  padding: 11px 20px;
  @media @mobile-menu {
    display: none;
  }
}

.navigation__item--signup {
  margin: 0 5px;
  padding: 13px 22px;
  margin-right: 30px;
}

.navigation__center {
  margin-left: auto;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.navigation__right {
  margin-left: auto;
}

.navigation__burger {
  box-sizing: content-box;
  height: 18px;
  width: 26px;
  right: 0;
  top: 0;
  position: absolute;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 35px;
  cursor: pointer;
  @media @desktop-menu {
    display: none;
  }
}

.navigation__burger__stripe {
  width: 100%;
  height: 2px;
  background: black;
}

.navigation--mobile {
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  height: 100dvh;
  width: 100dvw;
  z-index: 2 !important;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  display: none;
  @media @desktop-menu {
    display: none;
  }
  .navigation__item {
    display: block;
    margin: 10px auto;
    margin: 10px auto;
    max-width: 160px;
  }
}
