.footer {
  border-top: 1px solid #cacaca;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 1em;
}

.footer__eu {
  font-weight: bold;
  font-size: 20px;
}
.footer__eu-flag {
  font-size: 30px;
}
.footer__item {
  margin: 0 20px;
}